<template>
  <div>
    <b-modal @click="hideModal()" v-if="modal_insurance && modal_insurance.transport && modal_insurance.transport.number" :id="modalId" :hide-footer="true" :title="modal_insurance.mark_model + ' / ' + plateNumberWithSpaces(modal_insurance.transport.number)" size="s" centered>
      <h5>Вид страхования</h5>
      <b-card no-body style="border: none">
        <b-tabs card>
          <b-tab title="Обязательная" active>
            <div class="modal-company mt-3">
              <label for="company">Страховая компания</label><br><br>
              <b-form-select
                id="company"
                v-model="modal_insurance.compulsory_company"
                class="transport-add__value main__select"
                :options="listCompanies"
                text-field="text"
                value-field="name"
              />
              <img class="main__select-icon" src="@/assets/png/select-icon.png" alt="" />
            </div>
            <div class="modal-sum mt-3">
              <label for="sum">Сумма</label><br>
              <b-form-input v-mask="'##########'" id="sum" v-model="modal_insurance.compulsory_sum"  class="popup__inputs"></b-form-input>
            </div>
            <div class="modal-range mt-3">
              <label for="range">Срок действия страховки</label><br>
              <button class="filter__item-btn popup__inputs" style="height: calc(1.5em + 0.75rem + 2px); width: 100%" @click="dateShow = !dateShow">
                {{ compulsory_expired_at }}
                <b-icon
                  class="filter__item-btn-icon icon-right"
                  style="color: #707070; margin-left: 10px"
                  icon="calendar4-week"
                />
              </button>
              <div v-show="dateShow" class="sub-header__calendar">
                <vc-date-picker
                  v-model="range"
                  class="add__calendar"
                  color="green"
                  :rows="1"
                  is-range
                  @input="inputData()"
                />
                <button class="btn btn-primary mt-2" @click="hideModal()">Ok</button>
                <button class="btn btn-primary mt-2" @click="inputDataClean('')">Очистить</button>
              </div>
            </div>
          </b-tab>
          <b-tab @click="dateShowVoluntary = false" title="Добровольная">
            <div class="modal-company mt-3">
              <label for="company">Страховая компания</label><br><br>
              <b-form-select
                id="company"
                v-model="modal_insurance.voluntary_company"
                class="transport-add__value main__select"
                :options="listCompanies"
                text-field="text"
                value-field="name"
              />
              <img class="main__select-icon" src="@/assets/png/select-icon.png" alt="" />
            </div>
            <div class="modal-sum mt-3">
              <label for="sum">Сумма</label><br>
              <b-form-input v-mask="'##########'" id="sum" v-model="modal_insurance.voluntary_sum"  class="popup__inputs"></b-form-input>
            </div>
            <div class="modal-range mt-3">
              <label for="range">Срок действия страховки</label><br>
              <button class="filter__item-btn popup__inputs" style="height: calc(1.5em + 0.75rem + 2px); width: 100%" @click="dateShowVoluntary = !dateShowVoluntary">
                {{ voluntary_expired_at }}
                <b-icon
                  class="filter__item-btn-icon icon-right"
                  style="color: #707070; margin-left: 10px"
                  icon="calendar4-week"
                />
              </button>
              <div v-show="dateShowVoluntary" class="sub-header__calendar">
                <vc-date-picker
                  v-model="voluntary_range"
                  class="add__calendar"
                  color="green"
                  :rows="1"
                  is-range
                  @input="inputDataVoluntary()"
                />
                <button class="btn btn-primary mt-2" @click="hideModalVoluntary()">Ok</button>
                <button class="btn btn-primary mt-2" @click="inputDataCleanVoluntary('')">Очистить</button>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
      <div class="popup__buttons flex">
          <button :disabled="false" class="header__btn right-item default-btn popup__button1" 
            @click="modalClose()"
          >
              Отменить
          </button>
          <button  :disabled="false" class="header__btn right-item default-btn popup__button2" 
            @click="save()"
          >
              Сохранить
          </button>
      </div>
    </b-modal>
    <p v-if="insuranceList && insuranceList.meta" class="pogination__text">
      {{ insuranceList.meta.from }}-{{ insuranceList.meta.to }} из
      {{ insuranceList.meta.total }}
    </p>
    <b-pagination
      v-model="currentPage"
      v-if="insuranceList && insuranceList.meta"
      :total-rows="insuranceList.meta.total"
      :per-page="perPage"
      aria-controls="my-table"
      :hide-goto-end-buttons="true"
      :limit="1"
      class="pogination"
      @input="changePage()"
    >
      <template #prev-text>
        <img
          class="pagination__btn-icon pagination__btn-icon_prev"
          src="@/assets/png/database-icon-active.png"
          alt=""
        />
      </template>
      <template #next-text>
        <img class="pagination__btn-icon" src="@/assets/png/database-icon-active.png" alt="" />
      </template>
    </b-pagination>
    <div class="table">
      <b-table
        v-if="insuranceList"
        id="my-table"
        class="table__box"
        :borderless="true"
        :items="insuranceList.data"
        :fields="fields"
        large
      >
        <template #cell(auto)="data">
          <span style="font-weight: 600">{{ data.item.mark_model }}</span>
        </template>
        <template #cell(number)="data">
          <span style="font-weight: 600">{{ plateNumberWithSpaces(data.item.transport.number) }}</span>
        </template>
        <template #cell(company)="data">
          <!-- <span v-if="data.item.voluntary_company" style="font-weight: 600">{{ data.item.voluntary_company }}</span> -->
          <span v-if="data.item.compulsory_company" style="font-weight: 600">{{ data.item.compulsory_company }}</span>
        </template>
        <template #cell(compulsory)="data">
          <div class="working__info">
            <div class="working__road flex m-0" v-if="data.item.compulsory_started_at">
              <p class="working__road-text working__road_begin" :class="{'working__road_begin_generated': dateDiff(data.item.compulsory_started_at, data.item.compulsory_expired_at) > 80 }">{{ showDate(data.item.compulsory_started_at) }}</p>
              <div class="working__road-block">
                <div class="working__road-box" :class="{'working__road-generated': dateDiff(data.item.compulsory_started_at, data.item.compulsory_expired_at) > 80}" :style="dateDiffWidth(data.item.compulsory_started_at, data.item.compulsory_expired_at)" />
                <div class="working__road-box-after" />
              </div>
              <p class="working__road-text">{{ showDate(data.item.compulsory_expired_at) }}</p>
            </div>
          </div>
        </template>
        <template #cell(voluntary)="data">
          <div class="working__info">
            <div class="working__road flex m-0" v-if="data.item.voluntary_started_at">
              <p class="working__road-text working__road_begin" :class="{'working__road_begin_generated': dateDiff(data.item.voluntary_started_at, data.item.voluntary_expired_at) > 80 }">{{ showDate(data.item.voluntary_started_at) }}</p>
              <div class="working__road-block">
                <div class="working__road-box" :class="{'working__road-generated': dateDiff(data.item.voluntary_started_at, data.item.voluntary_expired_at) > 80}" :style="dateDiffWidth(data.item.voluntary_started_at, data.item.voluntary_expired_at)" />
                <div class="working__road-box-after" />
              </div>
              <p class="working__road-text">{{ showDate(data.item.voluntary_expired_at) }}</p>
            </div>
          </div>
        </template>
        <template #cell(action)="data">
          <div class="">
            <a class="table-btn">
              <b-icon
                icon="pencil-square"
                aria-hidden="true"
                class="mr-2"
                variant="secondary"
                @click="editModal(data.item)"
              />
            </a>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          key: "auto",
          label: "Авто",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "number",
          label: "Номер",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "company",
          label: "Страховая компания",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "compulsory",
          label: "Обязательная страховка",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "voluntary",
          label: "Добровольная страховка",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "action",
          label: "",
          sortable: false,
          isRowHeader: true,
          class: "tb-all tb-xs",
        },
      ],
      modal_insurance: {},
      modalId: null,
      listCompanies: [
        {name: '', text: 'Выберите'},
        {name: 'Jusan Garant', text: 'Jusan Garant'},
        {name: 'Freedom Insurance', text: 'Freedom Insurance'},
        {name: 'Centras Insurance', text: 'Centras Insurance'},
      ],
      dateShow: false,
      dateShowVoluntary: false,
      range: {},
      voluntary_range: {},
    };
  },
  computed: {
    ...mapState(["insuranceList", "textFilter", "dateFilter", "typeFilter"]),
    compulsory_expired_at() {
      if (this.modal_insurance.compulsory_expired_at) {
        return 'до ' + this.modal_insurance.compulsory_expired_at + ' г.'
      }
      
      return ''
    },
    voluntary_expired_at() {
      if (this.modal_insurance.voluntary_expired_at) {
        return 'до ' + this.modal_insurance.voluntary_expired_at + ' г.'
      }
      
      return ''
    },
  },
  async created() {
    if (this.$route.query.page != undefined && this.$route.query.page != 1) {
      this.currentPage = this.$route.query.page
    }
    await this.search();
  },
  methods: {
    changePage() {
      const searchURL = new URL(window.location);
      searchURL.searchParams.set('page', this.currentPage);
      window.history.pushState({}, '', searchURL)
    },
    plateNumberWithSpaces(x) {
      return x.toString().replace(/[^0-9](?=[0-9])|[0-9](?=[^0-9])/g, '$& ');
    },
    async search(){
      await this.$store.dispatch("getInsuranceList", {
        page: this.currentPage,
        expired_at: this.dateFilter,
        type_filter: this.typeFilter,
        search: this.textFilter,
      })
    },
    dateDiff(from, to) {

      if (from) {
        const date1 = new Date(from);
        const date2 = new Date();
        const date3 = new Date(to);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        const diffDaysEnd = Math.ceil(Math.abs(date3 - date1) / (1000 * 60 * 60 * 24));

        return diffDays/diffDaysEnd*100 > 95 ? 96 : diffDays/diffDaysEnd*100;
      } 
      
      return 0;
    },
    dateDiffWidth(from, to) {
      
      var diff = this.dateDiff(from, to)

      return 'width:' + diff+'%;';
    },
    showDate(date){
      if (!date)
        return;
      
      return new Date(date).toLocaleDateString('ru-RU', {day: '2-digit', month: '2-digit', year: 'numeric'})
    },
    editModal(item) {
      this.modal_insurance = structuredClone(item);
      this.modalId = 'bv-modal-insurance-' + item.id
      setTimeout(() => {
        this.$bvModal.show(this.modalId);
      }, 100);
    },
    modalClose() {
      this.$bvModal.hide(this.modalId);
      this.modalId = null;
      this.modal_insurance = {};
      this.range = {}
    },
    hideModal() {
      this.dateShow = false;
    },
    inputData() {
      if (this.range && this.range.start && this.range.end) {
        // Для случае когда не надо указывать конечную дату
        // const date = new Date(this.range.start);

        // this.range.end = date.setFullYear(date.getFullYear() + 1);
        // this.modal_insurance.compulsory_expired_at = this.range.end

        this.range.start = new Date(this.range.start).toISOString().split("T")[0];
        this.range.end = new Date(this.range.end).toISOString().split("T")[0];
        this.modal_insurance.compulsory_expired_at = this.range.end
        this.modal_insurance.compulsory_started_at = this.range.start
      }
      
    },
    inputDataClean() {
      this.range = [];
      this.hideModal();
    },
    hideModalVoluntary() {
      this.dateShowVoluntary = false;
    },
    inputDataVoluntary() {
      if (this.voluntary_range && this.voluntary_range.start && this.voluntary_range.end) {
        this.voluntary_range.start = new Date(this.voluntary_range.start).toISOString().split("T")[0];
        this.voluntary_range.end = new Date(this.voluntary_range.end).toISOString().split("T")[0];
        this.modal_insurance.voluntary_expired_at = this.voluntary_range.end
        this.modal_insurance.voluntary_started_at = this.voluntary_range.start
      }
      
    },
    inputDataCleanVoluntary() {
      this.voluntary_range = [];
      this.hideModalVoluntary();
    },
    save() {
      
      this.$api
        .post('/web/insurance/' + this.modal_insurance.id, this.modal_insurance)
        .then(() => {
          this.search();
          this.modalClose();
          this.$toast.success("Успешно");
        })
        .catch(() => {
          this.$toast.error('Что-то пошло не так, свяжитесь с администратором');
        });
    }
  },
  watch: {
    async currentPage() {
      await this.search();
    },
    async textFilter() {
      this.currentPage = 1;
      await this.search();
    },
    async dateFilter() {
      this.currentPage = 1;
      await this.search();
    },
    async typeFilter() {
      this.currentPage = 1;
      await this.search();
    },
  },
};
</script>

<style>
.table {
  width: 100%;
}
.table-name {
  display: flex;
  align-items: center;
  justify-content: center;
}
.table-name__btn {
  background: transparent;
}
.table-name__btn:hover {
  color: #1bb55c;
}
.pogination,
.pogination__text {
  position: absolute;
  top: -164px;
  right: -3px;
}
.pogination__text {
  right: 128px;
  top: -154px;
}
.page-item:nth-child(2) {
  display: none;
}
.page-item {
  margin-right: 18px;
  border: 1px solid #e0e9fa;
  border-radius: 8px;
}
.sub-header__text {
  margin: 0 20px 0 26px;
  color: #707070;
}
.pagination__btn-icon_prev {
  transform: rotate(180deg);
}
.city {
  background: #F2F2F8;
  border-radius: 5px;
  padding: 8px 10px;
  font-weight: 500;
}
.table-btn__status-pending {
  display: block;
  padding: 8px 10px;
  height: auto;
  border-radius: 5px;
  background: #FFF4D2;
  color: #FF9E0D;
}
.table-btn__status-busy {
  display: block;
  padding: 8px 10px;
  height: auto;
  border-radius: 5px;
  background: #FBD2CE;
  color: #E74C3C;
}
.success-img {
  background: #DCFFE4; 
  padding: 5px;
  border-radius: 5px;
}
.error-img {
  background: #FBD2CE; 
  padding: 5px;
  border-radius: 5px;
}
.working__road-box {
  background: #28A745;
}
.working__road-box::before {
  background: #28A745;
}
.working__road-box::after {
  border-color: #28A745
}
.working__road_begin {
  color: #28A745;
}
.working_way_name{ 
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}
.main-block {
  font-family: sans-serif;
}
.working__road-generated {
  background: #E74C3C;
}
.working__road-generated::after {
  border-color: #E74C3C;
}
.working__road-generated::before {
  background: #E74C3C;
}
.working__road_begin_generated {
  color: #E74C3C;
}
.popup__buttons {
  margin-top: 20px;
}

.popup__button1 {
  width: 48%;
  background: #F2F2F8;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: black;
}

.popup__button2 {
  width: 48%;
  background: #E0E9FA;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #0366D6;
}
.popup__inputs {
  margin-top: 10px;
  color: #ACACAC;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}
.add__calendar {
  position: static;
  background: #fff;
}
.sub-header__calendar {
  position: absolute;
  left: 22%;
  top: 0;
  z-index: 4;
  background: #f8f8f8;
  padding: 10px;
  border: 1px solid #dedede;
  display: flex;
  flex-direction: column;
}
.filter__item-btn {
  height: 100%;
  background: #ffffff;
  border: 1px solid #e0e9fa;
  border-radius: 8px;
  padding: 10px 35px 10px 10px;
  color: #707070;
  text-align: start;
  position: relative;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
}
.filter__item-btn-icon {
  right: 3px;
}
.main__select-icon {
  top: 40%;
}
</style>
